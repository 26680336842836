import React, { useState, FC } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem, Button, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Profile icon
import Logo from '../../nlai-logo-circle.png'; // Import the logo
import colors from '../../colors';

interface NavbarProps {
  setActiveTab: (tab: string) => void;
  onLogout: () => void;  // Added prop for handling logout
}

const Navbar: FC<NavbarProps> = ({ setActiveTab, onLogout }) => {
  const isMediumScreen = useMediaQuery('(max-width:960px)');
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setProfileAnchorEl(null);
  };

  const handleTabClick = (tab: string): void => {
    setActiveTab(tab);
    handleMenuClose();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: colors.light_primary, color: 'black' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <img src={Logo} alt="Logo" style={{ marginRight: '25px', height: '16px', transform: 'scale(3.6)', backgroundSize: 'cover', backgroundPosition: 'center' }} />
          Navigate Labs
        </Typography>
        {!isMediumScreen && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button color="inherit" onClick={() => handleTabClick('Assessment')}>Assessment</Button>
            <Button color="inherit" onClick={() => handleTabClick('AIInnovationPlatform')}>AI Innovation Platform</Button>
          </Box>
        )}
        <IconButton
          color="inherit"
          onClick={handleProfileMenuOpen}
          sx={{ ml: 2 }}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleTabClick('Profile')}>Profile</MenuItem>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
        <IconButton
          color="inherit"
          aria-label="menu"
          edge="start"
          onClick={handleMenuOpen}
          sx={{ display: isMediumScreen ? 'inline-flex' : 'none' }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="main-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleTabClick('Assessment')}>Assessment</MenuItem>
          <MenuItem onClick={() => handleTabClick('AIInnovationPlatform')}>AI Innovation Platform</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
