import React, { FC, useState } from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import colors from '../colors';
import { useNavigate } from 'react-router-dom';

interface AssessmentDetail {
  userEmail: string;
  assessmentId: string;
  title: string;
  description: string;
  dueDate: Date;
  attemptsAvailable: number;
  ended: boolean;
  reportReady: boolean;
}
const AssessmentCard: FC<AssessmentDetail> = ({ userEmail, assessmentId, title, description, dueDate, attemptsAvailable, ended, reportReady}) => {

  const navigate = useNavigate();

  const handleSelectAssessment = () => {
    navigate(`/instructions/${assessmentId}`);
  };


  return (
    <Card sx={{ backgroundColor: colors.white, color: colors.black}}>
      <CardContent>

        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          {title}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
          <Typography variant="body1" sx={{ color: colors.grey, maxHeight: '45px', overflowY: 'auto'}}>
            Description: {description}
          </Typography>
        </div>
        <Typography variant='body1' sx={{ marginBottom: '1rem' }}>
          Attempts Available: {attemptsAvailable}
        </Typography>
        {ended===false ? (
          <Button variant="contained" sx={{ backgroundColor: colors.primary, color: colors.white }} 
            onClick={() => handleSelectAssessment()}>
            START
          </Button>
          ):(
          <Button variant="contained" sx={{ backgroundColor: colors.warm_blue, color: colors.white }} 
            onClick={() => navigate(`/review/${assessmentId}`)}>
            REVIEW
          </Button>
        )}
        <Button  sx={{float: 'right' }} 
          disabled={reportReady===false}
          onClick={() => navigate(`/report/${assessmentId}`)}>
          VIEW REPORT
        </Button>

      </CardContent>
    </Card>
  );
};

export default AssessmentCard;