import { AppBar, Box, Button, FormControl, Paper, Toolbar, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Logo from '../nlai-logo-circle.png'; 
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';


const ReviewAssessment: FC = () => {

    const { assessmentId } = useParams();

    const navigate = useNavigate();
  
    const QUESTIONS_PER_PAGE = 1;

    const [questions, setQuestions] = useState<any[]>([]); 

    const [assessmentName, setAssessmentName] = useState('ASSESSMENT');
    

    useEffect(() => {
      const fetchAssessment = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/assessment_by_id/questions?id=${assessmentId}`
        const response = await fetch(
          apiUrl
        );
        console.log('fetchAssessment API response:', response);
        const data = await response.json();
        console.log('Assessment question fetched by Id:',data.questions);
        const questionsList = data.questions; 
        setAssessmentName(data.title);
        setQuestions(questionsList); 
      };
    
      fetchAssessment();
    }, 
    []); // Empty dependency array to fetch only once on component mount
    
return (
    <>
    <AppBar position="static" sx={{ backgroundColor: '#F7FFFD', color: 'black' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ mr: 2, flex:Logo}}>
          <img src={Logo} alt="Logo" style={{ marginRight: '25px', height: '16px', transform: 'scale(3.6)', 
          backgroundImage: `url(${Logo})`, backgroundSize: 'cover', backgroundPosition: 'center'}} />
          Navigate Labs
        </Typography>
      </Toolbar>
    </AppBar>
    <Typography variant="h4" sx={{ fontSize: '1.0rem', display: 'flex', justifyContent: 'center', marginY:'0.2rem', alignItems: 'baseline'}}>
        <Typography variant="overline" color="text.secondary"  sx={{fontSize: '1.5rem',fontWeight: 'bold'}}>
          { assessmentName }
        </Typography>
    </Typography>
    <Typography variant="caption" sx={{ fontSize: '1.0rem', fontWeight: 'bold', display: 'flex', justifyContent: 'center'
        , color: 'black', textDecoration: 'underline'}}>
            ASSESSMENT REVIEW
    </Typography>
    <Box sx={{ width: '45%', display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
      <Button onClick={() => navigate(`/dashboard`)}
      sx={{ width: '85%', display: 'flex', justifyContent: 'left'
      , alignItems: 'center', '&:hover': {textDecoration: 'underline'}}}>
          <ArrowLeftIcon fontSize="small" />
          <p>Back to Dashboard</p>
      </Button>
    </Box>
    <Box sx={{ overflowY: 'scroll', height: 'calc(100vh - 64px)' }}>
      <Box style={{ width: '85%', marginLeft: '14vh', marginRight: '14vh' }}>
        {questions.map((question, index) => {

          return (
            <Paper style={{ padding: '20px', marginBottom: '16px', marginTop: '16px' }}>
              <FormControl component="fieldset">
                <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <div>{index + 1}.</div>
                    <div style={{ marginLeft: '10px' }}>{question.questionText}</div>
                  </Typography>
                </Box>
                <Box sx={{ width: '100%', justifyContent: 'left', alignItems: 'center' }}>
                  <Typography sx={{ width: '100%', display: 'block', justifyContent: 'left', alignItems: 'center' }}>
                  
                    { (question.options[question.studentAnswer] === question.correctAnswer)  &&
                        <>
                        <Box sx={{ display: 'block', justifyContent: 'left', alignItems: 'center'}}>
                        <Button sx={{ justifyContent: 'left', alignItems: 'center' }}>
                            <CheckCircleOutlineRoundedIcon sx={{ color: 'green' }} />
                            <div style={{ marginLeft: '5px', color: 'green' }}>Correct</div>
                        </Button>
                        </Box>
                            {Object.keys(question.options).map((optionKey, innerIndex) => (
                                <p
                                    key={innerIndex}
                                    style={{ padding: '2%', backgroundColor: question.options[optionKey] === question.correctAnswer ? '#C2FAD3' : '', display: 'block', width: '100%' }}
                                >
                                {optionKey}. {question.options[optionKey]}
                                </p>
                            ))}
                        </>
                    }
                    { ( (question.studentAnswer === "") && 
                        (question.options[question.studentAnswer] !== question.correctAnswer) 
                      ) &&
                        <>
                        <Box sx={{ display: 'block', justifyContent: 'left', alignItems: 'center'}}>
                        <Button sx={{ justifyContent: 'left', alignItems: 'center' }}>
                            <CancelOutlinedIcon sx={{ color: 'red' }} />
                            <div style={{ marginLeft: '5px', color: 'red' }}>You didn't answer this</div>
                        </Button>
                        </Box>
                            
                            {Object.keys(question.options).map((optionKey, innerIndex) => (
                                <p  key={innerIndex}
                                    style={{ padding: '1%', backgroundColor: question.options[optionKey] === question.correctAnswer ? '#C2FAD3' : '', display: 'block', width: '95%' }}
                                >
                                {optionKey}. {question.options[optionKey]}
                                </p>
                            ))}
                        </>
                    }
                    { ( (question.studentAnswer !== "") && 
                        (question.options[question.studentAnswer] !== question.correctAnswer)
                      ) &&
                        <>
                        <Box sx={{ display: 'block', justifyContent: 'left', alignItems: 'center'}}>
                        <Button sx={{ justifyContent: 'left', alignItems: 'center' }}>
                            <CancelOutlinedIcon sx={{ color: 'red' }} />
                            <div style={{ marginLeft: '5px', color: 'red' }}>Wrong</div>
                        </Button>
                        </Box>

                            {Object.keys(question.options).map((optionKey, innerIndex) => (
                                <p
                                key={innerIndex}
                                style={{ padding: '2%', display: 'block', width: '100%', backgroundColor: ((question.options[optionKey] !== question.correctAnswer) ? ( (optionKey === question.studentAnswer) ? '#F69595' : '' ) : '#C2FAD3'),
                                }}
                                >
                                {optionKey}. {question.options[optionKey]}
                                </p>
                            ))}
                        </>
                    }
                    
                    <p>Explanation: {question.explanation}</p>

                  </Typography>
                </Box>
              </FormControl>
            </Paper>
          );
        })}
      </Box>
      </Box>
    </>
  );
};

export default ReviewAssessment;