import React, { useState, useEffect } from 'react';
import Question from './Question';
import { Button, Box, Typography, FormControlLabel, Checkbox, Paper } from '@mui/material';
import ConfirmationPopup from './ConfirmationPopup';
import { useParams, useNavigate } from 'react-router-dom';


const Instructions: React.FC = () => {
    const { assessmentId } = useParams(); // Destructure assessmentId from params

    const [showInstructions, setShowInstructions] = useState(true); // State to control instructions visibility
    const [canStartTest, setCanStartTest] = useState(false); // State to control button enable/disable

    const handleInstructionsRead = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setCanStartTest(event.target.checked); // Enable button on checkbox check
    };

    const navigate = useNavigate();

    const handleStartTest = () => {
        setShowInstructions(false); // Hide instructions on button click
        navigate(`/assessment/${assessmentId}`);
    };
    useEffect(() => {
      console.log("Instructions assessmentId",assessmentId);
    }, 
  []); // Empty dependency array to fetch questions only once on component mount
  

    return (
        <div>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
            <Paper elevation={3} style={{ width: '50%', padding: '20px', maxHeight: '50vh', overflowY: 'auto' }}>
              <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
                Instructions
              </Typography>
              <p>Read the following instructions carefully before taking the test:</p>
              <ul>
                <li >You can take this test only once.</li>
                <li>Don't switch tabs during the test.</li>
                <li>Navigating between questions is not enabled for this assessment.</li>
                <li>To move to the next question, click on the NEXT button.</li>
                <li>Click on SUBMIT ANSWERS button for each question to save the answer. Otherwise your answer won't be recorded.</li>
                <li>Click on END TEST button at the bottom right corner of the page to leave the test. Remember that once you leave the test, you can't take it again.</li>
              </ul>
            </Paper>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FormControlLabel
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              control={<Checkbox checked={canStartTest} onChange={handleInstructionsRead} />}
              label="I have read and understood the instructions."
            />
            <Button variant="contained" disabled={!canStartTest} onClick={() => handleStartTest()}>
              START TEST
            </Button>
          </Box>
        </div>
    );
};

export default Instructions;