import React, { useEffect, useState } from 'react';
import './App.css';
import Assessment from './components/Assessment';
import Dashboard from './components/Dashboard';
import Report from './components/Report/Report';
import Register from './components/Register'; 
import Landing from './components/Landing'; 
import Instructions from './components/Instructions';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReviewAssessment from './components/ReviewAssessment';


const App: React.FC = () => {

  return (
    <Router>
       <ToastContainer />
        <>
            <Routes>
              {/* <Route path="/" element={<Navigate replace to="/landing" />} /> */}
              <Route path="/" element={<Landing />} />
              <Route path="/register" element={<Register />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/instructions/:assessmentId" element={<Instructions />} />
              <Route path="/assessment/:assessmentId" element={<Assessment />} />
              <Route path="/report/:assessmentId" element={<Report />} />
              <Route path="/review/:assessmentId" element={<ReviewAssessment />} />
            </Routes>
        </>
    </Router>
  );
};

export default App;
