import React, { useState, useEffect, useRef } from 'react';

const LandingPageVideoBackground: React.FC = () => { 
  const videoRef = useRef<HTMLVideoElement>(null);
  const imageRef = useRef<HTMLImageElement>(null); 
  const [isPlaying, setIsPlaying] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false);

  const onVideoEnded = () => { 
    // landed = true;
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('ended', onVideoEnded); // Use the callback
    }
    return () => {
      if (video) {
        video.removeEventListener('ended', onVideoEnded);
      }
    };
  }, [videoRef]);

  return (
    <div className="login-page">
      {/* Video element */}
      <video id="backgroundVideo" autoPlay muted playsInline onEnded={onVideoEnded}> 
        <source src="./LandingVideo.mp4" type="video/mp4" />
      </video>

      {/* (Optional) Image element with conditional rendering */}
      <image>
      <source src="./LoginBackgroundNavigateLabsAI.jpg" type="image/jpg" />
      </image>
      {/* ... */}
    </div>
  );
};

export default LandingPageVideoBackground;
