import React, { useState, useEffect } from 'react';
import { googleLogout } from '@react-oauth/google';
import { Button, Box, Grid, Container } from '@mui/material';
import Navbar from './Navbar/Navbar';
import Register from './Register';
import AssessmentCard from './AssessmentCard';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface AssessmentDetail {
  _id: string;
  title: string;
  description: string;
  dueDate: Date;
  attemptsAvailable: number;
  ended: boolean;
  reportReady: boolean;
}

const Dashboard: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(location.state?.active_tab || 'Assessment');
  const [assessmentList, setAssessmentList] = useState<AssessmentDetail[]>([]);
  const [isStudentRegistred, setIsStudentRegistred] = useState(false);

  useEffect(() => {
    const checkRegistration = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/college/is-student-registered`;
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: userEmail }),
        });
        const data = await response.json();
        if (data.message === 'Student registered') {
          setIsStudentRegistred(true);
        } else {
          setIsStudentRegistred(false);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    };

    if (userEmail) {
      checkRegistration();
    } else {
      navigate('/');
    }
  }, [userEmail, navigate]); // Dependency on userEmail

  useEffect(() => {

    console.log('user email dashboard', userEmail);
    const fetchQuestions = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/assessment/questions?email=${userEmail}`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        setAssessmentList(data);
      } catch (error) {
        console.log("No assessments assigned for your login. Error: ", error);
      }
    };

    if (isStudentRegistred && userEmail) {
      fetchQuestions();
    }
  }, [userEmail, isStudentRegistred]);

  const handleLogout = () => {
    googleLogout();
    setIsLoggedIn(false);
    localStorage.removeItem('userEmail'); // Clear user email from localStorage
    navigate('/'); // Redirect to landing or login page
  };

  return (
    <Box onMouseLeave={() => setIsLoggedIn(false)}>
      <Navbar setActiveTab={setActiveTab} onLogout={handleLogout} />
      {isStudentRegistred ? (
        <Container sx={{ mt: 4 }}>
          {activeTab === 'Assessment' && (
            assessmentList.length > 0 ? (
              <Grid container spacing={3}>
                {assessmentList.map((assessment, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <AssessmentCard
                      assessmentId={assessment._id}
                      userEmail={userEmail}
                      title={assessment.title}
                      description={assessment.description}
                      dueDate={assessment.dueDate}
                      attemptsAvailable={assessment.attemptsAvailable}
                      ended={assessment.ended}
                      reportReady={assessment.reportReady}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '65vh' }}>
                <h2 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>No Assessment assigned for your login.</h2>
              </Box>
            )
          )}
          {/* Additional tabs like AIInnovationPlatform and Profile */}
        </Container>
      ) : (
        <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '65vh' }}>
          <Box>
            <h2 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>You are not currently registered as a student.</h2>
            <Button sx={{
                bgcolor: '#4caf50', // Set a green color
                color: '#ffffff', 
                '&:hover': {
                  bgcolor: '#388e3c' // Darker shade of green on hover
                },
                padding: '10px 20px', // Increase padding for better touch response and visibility
                borderRadius: '5px', // Rounded corners
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow for 3D effect
                fontSize: '16px', // Increase font size for better readability
                fontWeight: 'bold' // Bold font for prominence
              }}
              onClick={() => navigate(`/register`)}
            >
              Register Now
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
