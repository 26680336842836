import React from 'react';
import { Typography, Radio, RadioGroup, FormControl, FormControlLabel, Paper, Box, useTheme } from '@mui/material';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { IAssessmentQuestion } from '@shared/interfaces';

interface QuestionProps {
  questionData: IAssessmentQuestion;
  onAnswerUpdate: (answer: string, questionId: string) => void;
  selectedAnswer: string;
}

const Question: React.FC<QuestionProps> = ({ questionData, onAnswerUpdate, selectedAnswer }) => {
  const theme = useTheme();
  
  const customStyle = {
    background: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    fontFamily: '"Fira Code", "Fira Mono", monospace',
    fontSize: '1rem'
  };

  const renderContent = (content: string, keyPrefix: string) => {
    const codeRegex = /`{3}(\w+)?\s*([\s\S]*?)`{3}/g;
    const parts: JSX.Element[] = [];
    let lastIndex = 0;
    let match;

    while ((match = codeRegex.exec(content))) {
      const [fullMatch, lang, code] = match;
      const textBeforeCode = content.slice(lastIndex, match.index);

      if (textBeforeCode) {
        parts.push(<Typography key={`${keyPrefix}-text-${lastIndex}`}>{textBeforeCode}</Typography>);
      }

      parts.push(
        <SyntaxHighlighter
          key={`${keyPrefix}-code-${lastIndex}`}
          language={lang ? lang.trim() : 'plaintext'}
          style={solarizedLight}
          customStyle={customStyle}
        >
          {code.trim()}
        </SyntaxHighlighter>
      );

      lastIndex = codeRegex.lastIndex;
    }

    if (content.slice(lastIndex)) {
      parts.push(<Typography key={`${keyPrefix}-text-after-${lastIndex}`}>{content.slice(lastIndex)}</Typography>);
    }

    return parts;
  };

  return (
    <Paper elevation={3} sx={{ p: theme.spacing(3), mb: theme.spacing(2), borderRadius: '8px' }}>
      <FormControl component="fieldset" fullWidth>
        <Typography variant="subtitle1" gutterBottom component="div">
          Question: {questionData.questionId}
        </Typography>
        <Box mb={2}>
          {renderContent(questionData.questionText, `question-${questionData.questionId}`)}
        </Box>
        <RadioGroup
          name={`question_${questionData.questionId}`}
          value={selectedAnswer}
          onChange={(event) => onAnswerUpdate(event.target.value, questionData.questionId)}
        >
          {questionData.options && Object.keys(questionData.options).map((optionKey) => (
            <FormControlLabel
              key={optionKey}
              control={<Radio />}
              value={optionKey}
              label={questionData.options[optionKey]}
              sx={{ margin: theme.spacing(1, 0) }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

export default Question;
