import { useEffect, useState } from 'react';
import { Paper, Typography, Box, AppBar, Toolbar, Button, Card, CardContent, Stack } from '@mui/material';
import Logo from '../../nlai-logo-circle.png'; 
import colors from '../../colors';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useNavigate, useParams } from 'react-router-dom';

import TripleBarChart from '../graphs/TripleBarChart';
import ScatterPlot from '../graphs/ScatterPlot';


const Report = () => {
  const { assessmentId } = useParams();
  const [yourScore, setYourScore] = useState(0); 
  const [numberOfQuestions, setNumberOfQuestions] = useState(100); 
  const percentile = 100;
  const correctPerSubject = {'pyhton':25, 'java': 25};
  const analysisOverall = `Your overall performance in this assessment reflects your strong technical foundation. With an overall score of 71.57%, your results demonstrate a clear understanding across subjects. Here is the detailed breakdown:\nData Structures and Algorithms: You answered 19 out of 22 questions correctly.\nJava: Out of 15 questions, you got 11 right.\nPython: You answered 12 out of 19 questions correctly.\nSQL: You successfully answered 31 out of 46 questions.\nYour focus on foundational concepts and problem-solving approaches is evident from your scores across these areas.`;
  const strength = `Your correct answers highlight significant strengths that can propel your skills forward:\nData Structures and Algorithms: Your strong grasp of topics like Stacks, Binary Search, Graph Traversal, and Algorithms suggests a solid foundation in algorithmic thinking.\nJava: You demonstrated clear mastery in Java basics, OOP Abstraction, and Access Modifiers.
  Python: You excelled in variables, functions, and file handling, showing adaptability and problem-solving acumen.
  SQL: Your high scores in basic queries, joins, and aggregation functions affirm your strong data management capabilities.
  These strengths position you well for roles that demand logical reasoning, data analysis, and problem-solving skills. Building on them will help you excel in technical problem-solving.`;
  const improvement = `Your incorrect answers reveal opportunities for growth:

  Data Structures and Algorithms: Graph Algorithms and Sorting Algorithms are challenging but crucial areas to focus on.
  Java: Areas like OOP Inheritance and Array handling need reinforcement for a more comprehensive understanding.
  Python: Reviewing list manipulation, exception handling, and built-in functions will solidify your Python skills.
  SQL: Strengthen your knowledge in SQL constraints, joins, and query clauses for efficient database management.`;

  const [assessmentName, setAssessmentName] = useState('ASSESSMENT');

  useEffect(() => {
    const fetchQuestions = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/assessment/report?assessmentId=${assessmentId}`
      const response = await fetch(
        apiUrl
      );
      console.log('Fetch report by assessment ID - response:', response);
      const data = await response.json();
      // setAssessmentList(data);
      console.log("Report Data:", data);
      setAssessmentName(data.title);
      setYourScore(data.yourScore);
      setNumberOfQuestions(data.numberOfQuestions);
    };

    fetchQuestions();
  }, 
[]); // Empty dependency array to fetch questions only once on component mount

  const navigate = useNavigate();

  return (
    <>
    <AppBar position="static" sx={{ backgroundColor: colors.light_primary, color: 'black' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ mr: 2, flex:Logo}}>
          <img src={Logo} alt="Logo" style={{ marginRight: '25px', height: '16px', transform: 'scale(3.6)', 
          backgroundImage: `url(${Logo})`, backgroundSize: 'cover', backgroundPosition: 'center'}} />
          Navigate Labs
        </Typography>
      </Toolbar>
    </AppBar>
    
    <Typography variant="h4" sx={{ fontSize: '1.0rem', display: 'flex', justifyContent: 'center', marginY:'0.2rem', alignItems: 'baseline'}}>
        <Typography variant="overline" color="text.secondary"  sx={{fontSize: '1.5rem',fontWeight: 'bold'}}>
          MOCK ASSESSMENT { assessmentName }
        </Typography>
    </Typography>
    <Typography variant="caption" sx={{ fontSize: '1.0rem', fontWeight: 'bold', display: 'flex', justifyContent: 'center'
        , color: 'black', textDecoration: 'underline'}}>
            ASSESSMENT REVIEW
    </Typography>
    <Box sx={{ width: '45%', display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
      <Button onClick={() => navigate(`/dashboard`)}
      sx={{ width: '85%', display: 'flex', justifyContent: 'left'
      , alignItems: 'center', '&:hover': {textDecoration: 'underline'}}}>
          <ArrowLeftIcon fontSize="small" />
          <p>Back to Dashboard</p>
      </Button>
    </Box> 
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Paper elevation={3} style={{ width: '80%', padding: '20px', maxHeight: '71vh', overflowY: 'auto' }}>
    <Card>
        <CardContent>
          <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
              <Stack spacing={1}>
                <Typography color="text.secondary" variant="overline">YOUR SCORE</Typography>
                <Typography variant="h4">{yourScore} / {numberOfQuestions}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
              <Stack spacing={1}>
                <Typography color="text.secondary" variant="overline">ANALYSIS</Typography>
                <TripleBarChart />
                <ScatterPlot />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
              <Stack spacing={1}>
              <Typography variant="overline" sx={{fontSize: '1.5rem'}}>Analysing your performance</Typography>
              <Typography variant="overline" sx={{fontWeight: 'bold'}}>Overall Score and Subject-wise Analysis</Typography>
              <Typography variant="body2">{analysisOverall}</Typography>
              <Typography variant="overline" sx={{fontWeight: 'bold'}}>Strengths Identified</Typography>
              <Typography variant="body2">{strength}</Typography>
              <Typography variant="overline" sx={{fontWeight: 'bold'}}>Areas for Improvement</Typography>
              <Typography variant="body2">{improvement}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  </Box>
  </>
  );
};

export default Report;