import React, { FC, useState } from 'react';
import { ScatterChart } from '@mui/x-charts/ScatterChart';


interface ScatterPlotProps {}

const ScatterPlot: FC<ScatterPlotProps> = () => {

    // const [data, setData] = useState([
    //     { x: 1, y: 2 },
    //     { x: 2, y: 1 },
    //     { x: 3, y: 4 },
    //     { x: 4, y: 5 },
    //     { x: 5, y: 3 },
    //   ]);

      const data = [
        { id: 'point1', x: 1, y: 2 },
        { id: 'point2', x: 2, y: 1 },
        { id: 'point3', x: 3, y: 4 },
        // ... more data points
      ];
      
    
    //   // Function to generate random data points within a specified range
    //   const generateRandomData = (numPoints, xMin, xMax, yMin, yMax) => {
    //     const newData = [];
    //     for (let i = 0; i < numPoints; i++) {
    //       newData.push({
    //         x: Math.random() * (xMax - xMin) + xMin,
    //         y: Math.random() * (yMax - yMin) + yMin,
    //       });
    //     }
    //     setData(newData);
    //   };
    
      return (
        <ScatterChart
          /* ... */
          series={[{ data: data.map((point) => ({ ...point, z: point.x + point.y })) }]}
          xAxis={[{ colorMap: { type: 'piecewise',thresholds: [-1.5, 0, 1.5],
          colors: ['#d01c8b', '#f1b6da', '#b8e186', '#4dac26'],} }]}
          yAxis={[{}]}
          zAxis={[{}]}
        />
      );

};

export default ScatterPlot;