import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

interface ConfirmationPopupProps { 
  assessmentId: any;
  attemptsAvailable: number;
  onConfirm: (action: 'cancel' | 'endTest') => void ;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ assessmentId, attemptsAvailable, onConfirm }) => {
  
  const [open, setOpen] = useState(false);
  
  const handleEndTest = () => {
    setOpen(true); // Open the alert dialog
    // onConfirm('endTest');
    submitAssessment(assessmentId);
  };
  const backToDashboard = () => {
    setOpen(false) // Close the alert dialog
    onConfirm('endTest');
  };


  const submitAssessment = async (assessmentId:string) => {
    // Send the POST request to your backend endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/calculate_assessment_score?assessmentId=${assessmentId}`
        
    const response = await fetch(
      apiUrl,
      {
      method: 'GET'
    });
    console.log("Calculate Score Response:", response);

  };
  
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper style={{ padding: 20 }}>
          <Typography variant="h5">Are you sure you want to end the test?</Typography>
          <Typography>Number of attempts available: {attemptsAvailable - 1}</Typography>
          <Box display="flex" justifyContent="space-between" marginY={2}>
            <Button variant="outlined" onClick={() => onConfirm('cancel')}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleEndTest}>
              Yes, End Test
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>Test Submitted</DialogTitle>
              <DialogContent>
                Thank you for taking the test. Your answers are saved. The report for this assessment is available on your dashboard.
              </DialogContent>
              <DialogActions>
                <Button onClick={backToDashboard}>OK</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Paper>
      </div>
    );
  };
  
  export default ConfirmationPopup;