import React, { useState, useEffect } from 'react';
import Question from './Question';
import { Button, Box, Paper, Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@mui/material';
import ConfirmationPopup from './ConfirmationPopup';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface SelectedAnswers {
  [key: string]: string;
}

const Assessment: React.FC = () => {
  const { assessmentId } = useParams();
  
  const QUESTIONS_PER_PAGE = 1;
  const [attemptsAvailable, setAttemptsAvailable] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [questions, setQuestions] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswers>({});
  const [showEndTestConfirmation, setShowEndTestConfirmation] = useState(false);
  const [testEnded, setTestEnded] = useState(false);


  const navigate = useNavigate();
  const theme = useTheme();
  
  const handleEndTestClick = () => {
    setShowEndTestConfirmation(true); // Show confirmation popup
  };

  useEffect(() => {
    const fetchAssessment = async () => {
      console.log("Assessment assessmentId:",assessmentId);
      const apiUrl = `${process.env.REACT_APP_API_URL}/assessment_by_id/questions?id=${assessmentId}`;
      const response = await fetch(apiUrl);
      console.log('fetchAssessment API response:', response);
      const data = await response.json();
      console.log('Assessment question fetched by Id:',data.questions);
      const questionsList = data.questions; 
      setAttemptsAvailable(data.attemptsAvailable);
      setQuestions(questionsList); 
      setTotalPages(Math.ceil(questionsList.length / QUESTIONS_PER_PAGE));
    };
  
    fetchAssessment();
  }, 
  []); // Empty dependency array to fetch only once on component mount

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        endTest(); // Call the endTest function when a tab switch is detected
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const endTest = () => {
    console.log('Ending test due to tab switch...');
    setTestEnded(true); // Set the testEnded state to true to trigger the end test dialog
    endAssessment(assessmentId as string); // logic to send data to backend or log the end test event
    navigate('/dashboard'); // Navigate to dashboard or any other page you consider after ending the test
    toast.warn("Test ended due to switching tabs.", {
      position: "top-center",
      autoClose: false, // This disables automatic closing
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

    
  const handleConfirmationAction = (action: 'cancel' | 'endTest') => {
    if (action === 'cancel') {
      setShowEndTestConfirmation(false); // Dismiss popup
    } else if (action === 'endTest') {
      // Handle logic to end the test
      console.log('Ending test...'); // Replace with actual logic to end test on backend
      navigate('/dashboard'); // Navigate to dashboard using react-router-dom
      setShowEndTestConfirmation(false); // Dismiss popup after navigation
    }
  };

  // Function to handle answer selection
  const handleAnswerUpdate = (answer: any, questionId: any) => {
    console.log("Handling Answer Update");
    setSelectedAnswers({ ...selectedAnswers, [questionId]: answer });
    console.log("selectedAnswers:",selectedAnswers);
  };  

  const currentQuestions = questions.slice(
    (currentPage - 1) * QUESTIONS_PER_PAGE,
    currentPage * QUESTIONS_PER_PAGE
  );
  
  const submitAnswers = async () => {
    try {
      const data = {
        assessmentId: assessmentId,
        answers: selectedAnswers
      };
      console.log("answers:",data.answers);

      const apiUrl = `${process.env.REACT_APP_API_URL}/assessment/answer`;

      // Send the POST request to your backend endpoint
      const response = await fetch(
        apiUrl,
        {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) { 
        const responseData = await response.json();
        console.log('Submit Answers Response:', responseData); 

        toast.success("Your answers have been saved successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else {
        const responseData = await response.json();
        throw (responseData.error || '')
      }
      
    } catch (error) {
      console.error('Error submitting answers:', error);
      toast.error("Error submitting answers!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  const [open, setOpen] = useState(true);

  const endAssessment = async (assessmentId:string) => {
    // Send the POST request to your backend endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/calculate_assessment_score?assessmentId=${assessmentId}`
        
    const response = await fetch(
      apiUrl,
      {
      method: 'GET'
    });
    console.log("Calculate Score Response:", response);

  };
  
  const backToDashboard = () => {
    setOpen(false) // Close the alert dialog
    navigate(`/dashboard`);
  };

  return (
    <>
    {attemptsAvailable>0 ? (
      <Box>
      {currentQuestions.map((question, index) => (
        <Question
        key={question.id}
        // questionNumber={index + 1}
        questionData={question}
        selectedAnswer={selectedAnswers[question.questionId] || ''}
        onAnswerUpdate={handleAnswerUpdate}
      />
      ))}
      <Box display="flex" flexDirection="column-reverse">
        <Box display="flex" justifyContent="center" marginY={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            style={{ marginRight: '10px' }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => submitAnswers()}
            style={{ marginRight: '10px' }}
          >
            Submit Answers
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
          <Box position="absolute" bottom={20} right={20}>
            <Button variant="contained" color="error" onClick={handleEndTestClick}>
              END TEST
            </Button>
            {showEndTestConfirmation && (
              <ConfirmationPopup assessmentId={assessmentId} attemptsAvailable={attemptsAvailable} onConfirm={handleConfirmationAction} />
            )}
        </Box>
        </Box>
      </Box>
    </Box>
    ):(
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper style={{ padding: 20 }}>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Assessment Unavailable</DialogTitle>
            <DialogContent>
              Either you have exhausted the number of attempts or this assessment has been removed.
            </DialogContent>
            <DialogActions>
              <Button onClick={backToDashboard}>OK</Button>
            </DialogActions>
          </Dialog>
      </Paper>
    </div>
    )};

    {testEnded && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Test Ended"}</DialogTitle>
          <DialogContent>
            The test has been ended because you switched tabs.
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Assessment;