import React, { FC } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';


interface TripleBarChartProps {}

const TripleBarChart: FC<TripleBarChartProps> = () => {

  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: ['Python', 'Java', 'OOPs'] }]}
      series={[{ label: 'Your Score',data: [22, 24, 25] }, { label: "Total Score", data: [25, 25, 25] }, { label: "Average", data: [15, 20, 19] }]}
      width={500}
      height={300}
    />
  );

  };

export default TripleBarChart;
