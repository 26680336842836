import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from '../colors';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

interface UserData {
    email: string;
    firstName: string;
    lastName: string;
    college: string;
    newCollege: string; // To store new college name (if entered)
    studentId: string;
    batchStart: string;
    batchEnd: string;
    courseMajor: string;
    currentCGPA: string;
  }

// interface RegisterProps {
//   onSubmitHandler: (event: React.SyntheticEvent<HTMLFormElement>, userData: UserData) => void; // Function to handle form submission
// }

interface RegisterProps {
  // userEmail: string;
}

const Register: React.FC<RegisterProps> = () => {

  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    email: localStorage.getItem('userEmail') || '',
    firstName: '',
    lastName: '',
    college: "Kumaraguru College of Technology (KCT), Coimbatore",
    newCollege: '', // To store new college name (if entered)
    studentId: '',
    batchStart: '',
    batchEnd: '',
    courseMajor: '',
    currentCGPA: '',
  });
  const [colleges, setColleges] = useState([]); // Array for dropdown options
  const [registerMessage, setRegisterMessage] = useState(''); // To store any registration errors
  const [showPopup, setShowPopup] = useState(false);
  const [isEditAttempt, setIsEditAttempt] = useState(false); 

  const handleEditAttempt = () => {
    setIsEditAttempt(true);
  };

  useEffect(() => {
    if (!userData.email) {
      navigate('/');
    }
  }, [userData.email, navigate]);


  useEffect(() => {
    const fetchCollegeList = async () => {

      const apiUrl = `${process.env.REACT_APP_API_URL}/college-get/list-all`;

      const all_colleges = await fetch(apiUrl);
      
      const data = await all_colleges.json();

      // Extract college names only
      const collegeNames = data.collegeList.map((college: any) => college.name);
      setColleges(collegeNames);
    };
  
    fetchCollegeList();
  }, 
  []);

  
  const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    // if (name === 'college' && value === 'Other') {
    //   setUserData({ ...userData, newCollege: '' }); // Clear new college input when selecting "Other"
    // }
  };

  const handleNewCollegeChange = (event: { target: { value: any; }; }) => {
    setUserData({ ...userData, newCollege: event.target.value });
  };

  const handleRegisterSubmit = async (event: React.SyntheticEvent<HTMLFormElement>, userData: UserData) => {
    event.preventDefault();
  
    try {
      const { email, firstName, lastName, college, studentId } = userData; // Destructure data

      console.log("College (only on change in select):-",college);

      const findCollegeApiUrl = `${process.env.REACT_APP_API_URL}/college/find-college`

      const response_of_getCollege = await fetch(
        findCollegeApiUrl,
        {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json' 
          },
          body: JSON.stringify( { "name": college } ) 
        }
      );
    
      const collegeDetail = await response_of_getCollege.json();

      console.log("collegeId:",collegeDetail.college._id);
      const collegeId = collegeDetail.college._id;

      const studentDetailToRegister = {
        email, firstName, lastName, collegeId, studentId,
      };

      console.log("studentDetailToRegister:",studentDetailToRegister);

      const apiUrl = `${process.env.REACT_APP_API_URL}/college/register-student`;

      const response_of_registerStudent = await fetch(
        apiUrl,
        {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json' 
          },
          body: JSON.stringify(studentDetailToRegister) 
        }
      );

      const registerStudent_Response = await response_of_registerStudent.json();
      console.log("registerStudent_Response:", registerStudent_Response);
        
      await setRegisterMessage(registerStudent_Response.message); 
      console.log('message:', registerStudent_Response.message);
      setShowPopup(true);

      if(registerStudent_Response.message === 'Student registered successfully') {
          console.log("Yes Registered. Now creating default assessment.")
          const studentDetailToCreateAssessment = { 
            "studentEmail": email, 
            "firstName": firstName, 
            "lastName": lastName,
            "studentId": studentId,
            "collegeId": collegeId,
            "type": "Type No.4"
          }

          const apiUrl = `${process.env.REACT_APP_API_URL}/create-student-and-assignment`;

          const response_of_createStudentAssessment = await fetch(
            apiUrl,
            {
              method: 'POST', 
              headers: {
                'Content-Type': 'application/json' 
              },
              body: JSON.stringify(studentDetailToCreateAssessment) 
            }
          );
          const createStudentAssessment_Response = await response_of_createStudentAssessment.json();
          console.log("createStudentAssessment_Response:", createStudentAssessment_Response);
      }

    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  useEffect(() => {
    const backToDashboard = () => {
      if ( (registerMessage == 'Student already registered with email: niveditha.bvel@gmail.com') || (registerMessage == 'Email is already registered') ) {
        console.log("Yes registered")
        setShowPopup(false);
        navigate('/dashboard', { state: { active_tab: 'Assessment' } });
       }
    };
  
    backToDashboard();
  }, 
  []);

  const handlePopupReadOk = () => {
    // setRegisterMessage('');
    setIsEditAttempt(false);
    setShowPopup(false);
  }
  
  const Popup = ({ message }: { message: string }) => {
    console.log('message:', message);
    return (
      <div style={{ display: 'block', justifyContent: 'center', position: 'fixed', top: '20vh', left: '80%', transform: 'translateX(-50%)', backgroundColor: 'white', padding: '20px', border: '1px solid #ddd', borderRadius: '0.6rem', width: '15%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }} >
        <ErrorOutlineOutlinedIcon style={{ color: '#eda426' }}/>
        <p style={{ color: '#A1A1A1',  wordWrap: 'break-word', maxWidth: '100%' }}>{message}</p>
        </div>
        <button 
          style={{ background: 'white',  color: '#eda426', border: '1px solid #eda426', borderRadius: '0.6rem' }}
          onClick={handlePopupReadOk}>Ok</button>
        <button 
          style={{ background: '#eda426',  color: 'white', border: '1px solid #eda426', borderRadius: '0.6rem', marginLeft:'0.6rem' }}
          onClick={() => navigate('/dashboard', { state: { active_tab: 'Assessment' } }) }>Go to assessments</button>
      </div>
    );
  };

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5vh' }} >
      <img src="../nlai-logo-circle.png" alt="Logo" style={{ width: '100px'}} />
      <Typography variant='h4' >NAVIGATE LABS AI</Typography>
    </div>
    {/* <Box sx={{ width: '45%', display: 'flex', justifyContent: 'right', alignItems: 'center', height: '10px'}}>
      <Button onClick={() => navigate(`/dashboard`, { state: { active_tab: 'Assessment' } })}
      sx={{ width: '85%', display: 'flex', justifyContent: 'left'
      , alignItems: 'center', '&:hover': {textDecoration: 'underline'}}}>
          <ArrowLeftIcon fontSize="small" />
          <p>Go to Assessment</p>
      </Button>
    </Box>  */}
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '85vh' }}>
      <Paper elevation={3} style={{ width: '50%', padding: '20px', maxHeight: '100vh', backgroundColor: '#F7FFFD'}}>
      <Typography variant="h5" sx={{ textAlign: 'center', mb: 2, color: '#37DBB3' }}>
                REGISTER
      </Typography>
        {/* {registerMessage && <p style={{ color: 'red' }}>{registerMessage}</p>} */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <form onSubmit={(event) => handleRegisterSubmit(event, userData)}
              style={{justifyContent: 'center', width: '80%', alignItems: 'center'}}>
            <p style={{ fontSize: '12px' }}>All fields are required *</p>
            <label style={{ display: 'block', marginBottom: '15px', color: '#A6A6A6', fontSize: '15px'}}>Email</label>
            <div>
              <input style={{marginBottom: '30px', width: '100%', height: '30px', color: colors.grey}} type="email" name="email" value={userData.email} onFocus={handleEditAttempt} required readOnly/>
              {/* {isEditAttempt && ( <p style={{ position: 'absolute', top: '50%', left: '50%', padding: '5px 10px',  background: '#ffff', border: '1px solid #f0f0f0', fontSize: '0.8rem', display: 'block' }} >Email can't be editable</p> )} */}
              {isEditAttempt && <Popup message={'Email can not be edited'} />}
            </div>
            <div style={{ display: 'flex', width: '100%'}}>
              <div style={{ alignSelf: 'left', width: '50%'}}>
                <label style={{ display: 'block', marginBottom: '10px', color: '#A6A6A6', fontSize: '15px'}}>First Name</label>
                <input style={{marginBottom: '30px', width: '100%', height: '30px'}}  type="text" name="firstName" value={userData.firstName} onChange={handleInputChange} onClick={() => setIsEditAttempt(false)} required />
              </div>
              <div style={{ alignSelf: 'right', width: '50%', marginLeft: '5vh'}}>
                <label style={{ display: 'block', marginBottom: '10px', color: '#A6A6A6', fontSize: '15px' }}>Last Name</label>
                <input style={{marginBottom: '30px', width: '100%', height: '30px'}}  type="text" name="lastName" value={userData.lastName} onChange={handleInputChange} onClick={() => setIsEditAttempt(false)} required />
              </div>
            </div>
            <label style={{ display: 'block', marginBottom: '10px', color: '#A6A6A6', fontSize: '15px' }}>College</label>
            <select style={{marginBottom: '30px', width: '100%', height: '30px'}}  name="college" value={userData.college} onChange={handleInputChange} onClick={() => setIsEditAttempt(false)} >
              {colleges.map((college) => (
                <option key={college} value={college}>
                  {college}
                </option>
              ))}
            </select>
            <label style={{ display: 'block', marginBottom: '10px', color: '#A6A6A6', fontSize: '15px' }}>Registration Number</label>
            <input style={{marginBottom: '30px', width: '100%', height: '30px', fontFamily: 'inherit' }}  type="text" name="studentId" placeholder='Your unique ID registered in college' value={userData.studentId} onChange={handleInputChange} onClick={() => setIsEditAttempt(false)} required />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type='submit' sx={{ fontWeight: 'bold', color: '#fff', padding: '10px 20px', borderRadius: '5px', backgroundColor: '#69E2B3'
                                      , boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', '&:hover': {textDecoration: '#69E2B3'}, left: '0'}}
                >Submit</Button>
                {/* <br />
                <Button 
                  sx={{color: '#69E2B3', '&:hover': {textDecoration: 'underline'}, right: '5vh'}}
                  onClick={() => navigate(`/`)}
                >Already a member?</Button>  */}
                {/* ... other form fields */}
            </div>
            </div>
        </form>
        {showPopup && <Popup message={registerMessage} />}
        </div>
      </Paper>
    </Box>
    </>
  );
};


export default Register;
