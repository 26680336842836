import React, { useEffect, useState } from 'react';
import LandingPageVideoBackground from './LandingPageVideoBackground';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Button, Container } from '@mui/material';

interface LandingProps {
  // onSetUserEmail: (userEmail: string) => void;
}

const Landing: React.FC<LandingProps> = () => {

      const [landed, setlanded] = useState(false);
      const [activeTab, setActiveTab] = useState<string>('Assessment'); // Default active tab is "Assessment"

      const navigate = useNavigate();

      useEffect(() => {
        const timeoutId = setTimeout(() => {
          setlanded(true); 
        }, 7000); // Timeout set to 7 seconds (7000 milliseconds)
    
        return () => clearTimeout(timeoutId); // Cleanup function to clear timeout on unmount
      }, []);

      useEffect(() => {
        const userEmail = localStorage.getItem('userEmail');
        const activeTab = localStorage.getItem('activeTab');
      
        if (userEmail) {
          // onSetUserEmail(userEmail);  // Updates state higher in your app
          navigate('/dashboard', { state: { active_tab: activeTab || 'Assessment' } });
        }
      }, [navigate]);  // Ensures effect runs with the latest handler
    
      const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
          // console.log(tokenResponse);
          // fetching userinfo can be done on the client or the server
          const userInfo = await axios
            .get('https://www.googleapis.com/oauth2/v3/userinfo', {
              headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            })
            .then(res => res.data);
    
          console.log("UserInfo:",userInfo);
          console.log("User Email:",userInfo.email);
          // onSetUserEmail(userInfo.email);
          localStorage.setItem('userEmail', userInfo.email); // Save user email in localStorage
          localStorage.setItem('activeTab', 'Assessment'); // Save default or current active tab
          navigate(`/dashboard`, { state: { active_tab: 'Assessment' } });
        }, })
    
      const handleLoginSuccess = (response: any) => {
        // const userEmail = response.profileObj.email;
        // console.log("Logged in user email:", userEmail);
        console.log('Login Success:', response);
      };    
    
    
    return (
        <>
              <LandingPageVideoBackground />
              {console.log("Landed:",landed)}
              {landed && 
                ( // Conditionally render the button only after landed state is true
                    <Container style={{ position: 'absolute', bottom: '25vh', right: '10vh', width:"20%"}}>
                        <GoogleOAuthProvider clientId="...">
                            <Button
                                style={{ fontSize: '0.8rem', fontWeight: 'bold', color: '#fff', padding: '10px 20px', borderRadius: '5px', backgroundColor: '#69E2B3', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'}}
                                onClick={() => login()}
                            >Login with Google
                            </Button>
                        </GoogleOAuthProvider>
                        {/* <Button 
                            sx={{color: '#69E2B3', '&:hover': {textDecoration: 'underline'}, right: '5vh'}}
                            onClick={() => navigate(`/register`)}
                            >Not Registered yet?</Button>  */}
                    </Container>
                )
            }
        </>
    );
}

export default Landing;