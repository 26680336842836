const colors = {
  primary: '#69E2B3',
  light_primary: '#F7FFFD',
  black: '#000000',
  white: '#FFFFFF',
  grey: '#A1A1A1',
  red: '#EC312C',
  warm_blue: '#00A4CD'
};

export default colors;
